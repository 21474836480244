import React from "react";
import i18next from "i18next";
import { NavLink } from "react-router-dom";
import DefaultLayout from "./../layouts/Default";
import { clearFilters } from "../store/filter/actions";

export default function ImprintPage() {
    return (
        <DefaultLayout
            content={
                <div className="page__content container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <NavLink
                                className="category__backlink home-backlink active"
                                to={`/`}
                                onClick={() => clearFilters()}
                            >
                                {i18next.t("Home")}
                            </NavLink>
                            <h1 className="mb-4">Impressum</h1>
                            <div class="col-12 mb-5">
                                <p>
                                    <br />
                                </p>

                                <div class="row mt-4">
                                    <h4 style={{ width: "100%" }}>
                                        Unfallkasse Nordrhein-Westfalen
                                        (Unfallkasse NRW)
                                    </h4>
                                    <div class="col-md-6">
                                        <p>
                                            Moskauer Straße 18
                                            <br />
                                            40227 Düsseldorf
                                            <br />
                                            Tel.: 0211 / 2808 - 200
                                            <br />
                                            Fax: 0211 / 2808 - 209
                                            <br />
                                            E-Mail: info@unfallkasse-nrw.de
                                            <br />
                                            IK-Nummer: 120591802
                                        </p>
                                    </div>
                                </div>
                                <p>
                                    Die Unfallkasse NRW ist eine Körperschaft
                                    des öffentlichen Rechts. Die Unfallkasse NRW
                                    ist ein Unfallversicherungsträger der
                                    öffentlichen Hand für die gesetzliche
                                    Unfallversicherung in NRW.{" "}
                                </p>
                                <p>
                                    Sie wird gerichtlich und außergerichtlich
                                    vertreten durch den Vorstand bzw. die{" "}
                                    <a
                                        href="https://www.unfallkasse-nrw.de/ueber-uns/organisation/geschaeftsfuehrung.html"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Geschäftsführung
                                    </a>
                                    . (siehe{" "}
                                    <a
                                        href="https://www.unfallkasse-nrw.de/fileadmin/server/download/PDF_Container/Satzung_UK_NRW_5___Nachtrag.pdf"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Satzung&nbsp;
                                    </a>
                                    der Unfallkasse § 17){" "}
                                </p>
                                <p>
                                    Inhaltlich Verantwortlicher gemäß § 55 Abs.
                                    2 Rundfunkstaatsvertrag (RStV): Michael Stock, Geschäftsführer <br />
                                    <em>(Anschrift wie oben)</em>
                                </p>
                                <div class="row mt-4">
                                    <h4>
                                        In Zusammenarbeit mit - rend Medien
                                        Service GmbH
                                    </h4>
                                </div>
                                <div class="col-md-4">
                                    <p>
                                        Bahnhofstrasse 1c
                                        <br />
                                        41747 Viersen
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <p>
                                        E-Mail: info@rend.de
                                        <br />
                                        Webseite:{" "}
                                        <a
                                            href="https://www.rend.de/"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            www.rend.de
                                        </a>
                                    </p>
                                </div>
                                <hr />
                                <div>
                                    <p>
                                        <strong>Rechtliche Hinweise</strong>
                                    </p>
                                    <p>
                                        Eine Haftung für die Aktualität,
                                        Richtigkeit und Vollständigkeit der zur
                                        Verfügung gestellten Informationen kann
                                        trotz dauernder Prüfung der Inhalte
                                        nicht übernommen werden. Inhalt und
                                        Struktur der Webseiten der Deutschen
                                        Gesetzlichen Unfallversicherung sind
                                        urheberrechtlich geschützt.{" "}
                                    </p>
                                    <p>
                                        Die Deutsche Gesetzliche
                                        Unfallversicherung übernimmt keine
                                        Haftung und ist nicht verantwortlich für
                                        die Inhalte fremder Seiten, die über
                                        einen Link erreicht werden können.
                                    </p>
                                </div>
                                <h4>&nbsp;</h4>
                            </div>
                        </div>
                    </div>
                </div>
            }
        ></DefaultLayout>
    );
}
