import React from 'react';
import { 
    useHistory,
    useLocation
} from "react-router-dom";
import './CategoriesCheckboxList.sass';

import PropTypes from 'prop-types'


export default function CategoriesCheckboxList(props) {
    let location = useLocation();
    let history = useHistory()
    //let query = new URLSearchParams(useLocation().search);
    //let formatId = parseInt(query.get("format"));
    
    function activeCheckbox(id) {
        return props.categoriesSelected.includes(id) ? 'checked' : false;
    }

    /*function filterFilter(categoryId){
        let list = [...props.cardsCategorys]
        let categoriesSelected = [...props.categoriesSelected]
        let newCategories
        if(!props.categoriesSelected.includes(categoryId)){
            newCategories = [...props.categoriesSelected, categoryId]
        }else{
            newCategories = [...props.categoriesSelected]
        }
        if(formatId){
            list = list.filter(card => card.format === formatId)
        }
        if(props.switchStatus){
            list = list.filter(card => props.filters.includes(card.type))
        }
        let testList = list.filter(card => newCategories.every(categorie => card.categories.includes(categorie)))
        let newList = list.filter(card => categoriesSelected.every(categorie => card.categories.includes(categorie)))
        let tl = testList.length
        let nl = newList.length
        if(tl === 0 || tl-nl === 0){
            return 0
        } else{
            return 1
        }
    }

    function checkNumber(categoryId){
        if(props.categoriesSelected.includes(categoryId)){
            return 1
        } else{
            return filterFilter(categoryId)
            
        }
    }*/

    function handleCategoriesetter(categories) {
        let array = props.categoriesSelected;
        if(categories.length === 1){
            for(let category of categories) {
                if(array.indexOf(category) === -1) {
                    array.push(category);
                } else {
                    array.splice(array.indexOf(category), 1);
                }
            }
        } else {
            let checkSigned = array.filter(item => {
                return categories.indexOf(item) !== -1;
            }).length !== 0;
            if(!checkSigned) {
                array = [...array, ...categories];
            } else {
                array = array.filter(item => {
                    return categories.indexOf(item) === -1;
                });
            }
        }
        if(location.pathname !== '/') {
            history.push('/');
        }
        props.setCurrentCategories([...array]);
    }
    
    return (
            <ul className="categories-list">
                {props.categories.map(category => {
                    return(
                        /*checkNumber(category.id) === 0 ?
                            <li key={ category.id } id={ category.id } className="hidden">
                            </li> 
                        :*/
                            <li
                                className="categories-list__item"
                                key={ category.id }
                                id={ category.id }>
                                <label className="label">
                                    <input 
                                        type="checkbox"
                                        checked={ activeCheckbox(category.id) }
                                        onChange={ () => handleCategoriesetter([category.id]) }
                                        className="mr-2"/>
                                    <span>{ category.title }</span>
                                </label>

                            </li>
                    )                
                }) }
            </ul>
    )
}

CategoriesCheckboxList.defaultProps = {
    categories: []
}

CategoriesCheckboxList.propTypes = {
    categoriesSelected: PropTypes.array,
    setCurrentCategories: PropTypes.func,
    categories: PropTypes.array
}