import React from 'react';

export default class VideoPlaybackSettingsForm extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            videoPlaybackConfirmed: !!localStorage.getItem('videoPlaybackConfirmed')
        }
    }
    
    handleConfirm = (status) => {
        if (status) {
            localStorage.setItem('videoPlaybackConfirmed', 'yes');
            this.setState({
                videoPlaybackConfirmed: true
            });
        } else {
            localStorage.removeItem('videoPlaybackConfirmed');
            this.setState({
                videoPlaybackConfirmed: false
            });
            
        }
    }

    render() {
            return (
                <div style={{border: '1px solid #888', padding: '2rem'}}>
                    <p>Aktueller Status:  {this.state.videoPlaybackConfirmed ? <i>Externe Inhalte werden angezeigt</i> : <i>Externe Inhalte werden nicht angezeigt</i>}</p>
                    {!this.state.videoPlaybackConfirmed &&
                        <div>
                            <p>Sie haben keine Einwilligung für die generelle Einbindung externer Inhalte gegeben. Sie werden bei jedem externen Inhalt erneut nach Ihrem Einverständnis gefragt.</p>
                            <button type="button" onClick={ () =>  this.handleConfirm(true)}>Externe Inhalte erlauben</button>
                        </div>
                    }
                    {this.state.videoPlaybackConfirmed &&
                        <div><p>Sie haben der Einbindung von externen Inhalten zugestimmt. Wenn Sie Ihre Einwilligung widerrufen, werden Sie vor der Einbindung von externen Inhalten erneut nach Ihrem Einverständnis gefragt.</p>
                            <p><button type="button" onClick={ () =>  this.handleConfirm(false)}>Einwilligung widerrufen</button></p>
                        </div>
                    }
                </div>
        )
    }
}