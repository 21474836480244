import React from 'react';
import './Header.sass';
import Logo from './../Logo/LogoContainer';
import MyCollectionDropdown from './../../desktop/MyCollectionDropdown/MyCollectionDropdownContainer';
import inResponsiveSizes from '../../../lib/ui/inResponsiveSizes';
import Shepherd from '../Shepherd/Shepherd';
import PropTypes from 'prop-types'
import { clearFilters } from '../../../store/filter/actions';


export default class Header extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            filterOpened: true,
            searchOpened: true
        };
    }

    render(){
        return (
            <header 
                className="header page__header">
                <div className="header-container">
                {/* Page block */}
                <Logo
                    class="header__logo"
                    brandName={ this.props.title }
                    screenSize={ this.props.screenSize }
                    groupesList={ this.props.groupesList }
                    clearFilters={ clearFilters }
                /> 
                {(inResponsiveSizes(['lg', 'xl'], this.props.screenSize)) ?
                    <Shepherd type="desktop" /> 
                : 
                    <></>
                }   
                {(inResponsiveSizes(['sm', 'md'], this.props.screenSize)) ?
                    <Shepherd type="mobile" /> 
                : 
                    <></>
                } 
                {/* Collection dropdown block */}
                { (!inResponsiveSizes(['xs', 'sm'], this.props.screenSize)) &&
                    <div className="header__user-collection">
                        <MyCollectionDropdown
                            className="header__dropdown-collection"
                            myCardsCollection={ this.props.myCardsCollection }
                            setMyCardsCollection={ this.props.setMyCardsCollection } />
                    </div>
                }
                {/* Logo block */}
                <span className="logo__mediathek">
                    <a href="https://www.sichere-feuerwehr.de/" target="_blank" rel="noopener noreferrer">
                        <span className="logo-container">
                            <img
                                src="svg/uk-nrw-logo.png"
                                alt="sichere-feuerwehr" />
                        </span>
                    </a>
                </span>
                </div>
            </header>
        )
    }
}

Header.propTypes = {
    title: PropTypes.string,
    screenSize: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    myCardsCollection: PropTypes.array,
    setMyCardsCollection: PropTypes.func,
    groupesList: PropTypes.array
}