import React from 'react';
import i18next from 'i18next';
import {NavLink} from 'react-router-dom';
import DefaultLayout from './../layouts/Default';
import {
    clearFilters
} from '../store/filter/actions';


export default function BarrierefreiPage(props) {

    return (
        <DefaultLayout
            content={
                <div className="page__content container mt-4">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <NavLink
                                className="category__backlink home-backlink active"
                                to={`/`}
                                onClick={() => clearFilters()}>
                                {i18next.t('Home')}
                            </NavLink>
                            <h1 className="mb-4">Erklärung zur Barrierefreiheit</h1>
                            <p>Als öffentliche Stelle im Sinne der Richtlinie (EU) 2016/2102 sind wir bemüht, unsere
                                Website im Einklang mit den Bestimmungen des Behindertengleichstellungsgesetzes des
                                Bundes (BGG) sowie der Barrierefreien-Informationstechnik-Verordnung (BITV 2.0) zur
                                Umsetzung der Richtlinie (EU) 2016/2102 barrierefrei zugänglich zu machen.</p>

                            <p>Diese Erklärung zur Barrierefreiheit gilt für die aktuell im Internet erreichbare
                                Version dieser Website (https://sifw-media.sichere-feuerwehr.de/).</p>
                            <p><strong>Konformitätsstatus</strong></p>
                            <p>Die Anforderungen der Barrierefreiheit ergeben sich aus §§ 3 Absätze 1 bis 4 und 4 der
                                BITV 2.0, die auf der Grundlage von § 12d BGG erlassen wurde. Die Überprüfung der
                                Einhaltung der Anforderungen beruht auf einer am 07.02.2022 durchgeführten
                                Selbstbewertung. Aufgrund dieser Prüfung ist die Website mit den zuvor genannten
                                Anforderungen teilweise vereinbar. Die Webseite wird nach den geltenden Anforderungen
                                überarbeitet. Wir arbeiten an den notwendigen Prozessen, die Informationen in
                                Bezug auf die digitale Barrierefreiheit fortlaufend weiter zu verbessern.</p>
                            <p>Welche Teile sind nicht barrierefrei?</p>
                            <ul>

                                <li>Die verlinkten PDF-Dokumente sind teilweise barrierefrei bzw. nur barrierarm.</li>
                                <li>Videos sind in vielen Fällen noch ohne Untertitel und liegen
                                    ohne Audiodeskription und Volltext-Alternative vor. Wir bemühen uns, Ihnen
                                    eine barrierefreie Version bereitzustellen.
                                </li>
                                <li>Die Alternativtexte für Grafiken und Objekte auf der Website werden schrittweise
                                    gemäß den Richtlinien für Barrierefreiheit überarbeitet.
                                </li>
                                <li>Der Text der Webseiten zur Erklärung zur Barrierefreiheit,
                                    zum Feedback-Mechanismus sowie die Seite - Was ist "Sichere Feuerwehr 
                                    - Die Mediathek?" - und das Inhaltsverzeichnis sind noch nicht
                                    in Deutscher Gebärdensprache verfügbar. Damit sind diese Webseiten mit
                                    dem Erfolgskriterium 1.2.6 (Gebärdensprache) nicht vereinbar.
                                </li>
                            </ul>
                            <p><strong>Informationen in Leichter Sprache</strong><br></br>
                                In Kürze stellen wir hier die wesentlichen Informationen des Portals dar und erklären
                                die Bedienung der Webseite.</p>
                            <p><strong>Informationen in Deutscher Gebärdensprache (DGS)</strong><br></br>
                                Wir werden Sie an dieser Stelle über die Bedienung und Inhalte von
                                zuhause-pflegen-mediathek.unfallkasse-nrw.de mit Videos in Deutscher Gebärdensprache
                                (DGS) informieren.</p>
                            <p><strong>Datum der Erstellung der Erklärung</strong><br></br>
                                Diese Erklärung wurde am 07.02.2022 erstellt.</p>
                            <p><strong>Barriere melden! Feedback zur Barrierefreiheit</strong><br></br>
                                Sie möchten uns bestehende Barrieren mitteilen oder Informationen zur Umsetzung der
                                Barrierefreiheit erfragen?
                                Für Ihr Feedback sowie alle weiteren Informationen sprechen Sie uns unter dieser
                                E-Mail-Adresse an: <a
                                    href="mailto:feedback-barrierefreiheit@rend.de">feedback-barrierefreiheit@rend.de</a>
                            </p>
                            <p><strong>Schlichtungsverfahren</strong><br></br>
                                Wenn auch nach Ihrem Feedback an den oben genannten Kontakt keine zufriedenstellende
                                Lösung gefunden wurde, können Sie sich an die Schlichtungsstelle nach § 16
                                BGG wenden. Die Schlichtungsstelle BGG hat die Aufgabe, bei Konflikten zum
                                Thema Barrierefreiheit zwischen Menschen mit Behinderungen und öffentlichen
                                Stellen des Bundes eine außergerichtliche Streitbeilegung zu
                                unterstützen. Das Schlichtungsverfahren ist kostenlos. Es muss kein
                                Rechtsbeistand eingeschaltet werden. Weitere Informationen zum
                                Schlichtungsverfahren und den Möglichkeiten der Antragstellung erhalten Sie unter: <a
                                    href="http://www.schlichtungsstelle-bgg.de/" target="_blank"
                                    rel="noopener noreferrer"> www.schlichtungsstelle-bgg.de</a>.<br></br>
                                Direkt kontaktieren können Sie die Schlichtungsstelle BGG
                                unter <a href="mailto:info@schlichtungsstelle-bgg.de">info@schlichtungsstelle-bgg.de</a>.
                            </p>
                        </div>
                    </div>
                </div>
            }>
        </DefaultLayout>
    )
}